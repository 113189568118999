import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaProps {
  title: string | undefined;
  description: string | undefined;
  keywords: string | undefined;
}

const withMeta :React.FC<MetaProps> = ({title,description,keywords}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
    </>
  );
};

export default withMeta;
