import Layout from "antd/es/layout/layout";
import HomeFooter from "../Home/Footer/HomeFooter";
import HomeHeader from "../Home/Header/HomeHeader";
import { apiArticleList } from "../../libs/http/api";
import { useRequest } from "ahooks";
import styled from "styled-components";
import { Flex } from "antd";
import { isMobile, px2vw } from "../../libs/utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useVoerkaI18n } from "@voerkai18n/react";
import { t } from "../../languages";
import { useParams } from "react-router-dom";
import map_detail_img_1 from "../../assets/scenes/map_detail_img_1@2x.png";
import map_detail_img_2 from "../../assets/scenes/map_detail_img_2@2x.png";
import map_detail_img_3 from "../../assets/scenes/map_detail_img_3@2x.png";
import map_detail_img_4 from "../../assets/scenes/map_detail_img_4@2x.png";
import map_detail_img_5 from "../../assets/scenes/map_detail_img_5@2x.png";
import map_detail_img_7 from "../../assets/scenes/map_detail_img_7@2x.png";
import map_detail_img_8 from "../../assets/scenes/map_detail_img_8@2x.png";
import map_detail_img_9 from "../../assets/scenes/map_detail_img_9@2x.png";
import map_detail_img_10 from "../../assets/scenes/map_detail_img_10@2x.png";
import map_detail_img_11 from "../../assets/scenes/map_detail_img_11@2x.png";
import map_detail_img_12 from "../../assets/scenes/map_detail_img_12@2x.png";
import map_detail_img_13 from "../../assets/scenes/map_detail_img_13@2x.png";
import map_detail_img_14 from "../../assets/scenes/map_detail_img_14@2x.png";
import { useUpload } from "../../components/FileUploadContext";
import React, { useRef } from "react";
import withMeta from "../../components/withMeta";
import { logEvent } from "../../libs/analytics";

export interface ContentItem {
  title: string;
  content: JSX.Element;
  docTitle?: string;
  d?: string;
  k?: string;
}

export interface ContentDictionary {
  [key: string]: ContentItem;
}

export default function UseScenes() {
  const { addFiles } = useUpload();
  const { id } = useParams();
  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      logEvent("Home", "file_upload", "upload");
      addFiles(Array.from([file]));
      navigate("/historical");
    }
  };

  const contentDictionary: ContentDictionary = {
    "0": {
      title: t("图书转文字"),
      content: (
        <Flex vertical>
          <Flex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("减少95%存储成本")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("通过图片转文字进行存储")}</b> <br />
                {t("90MB轻松转为6MB")}
                <br />
                {t("瘦身文件简简单单")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_13}></ContentImage>
          </Flex>
          <Flex>
            <ContentImage src={map_detail_img_14}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>
                {t("你可以存更多")}
              </ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("存储更多，看见更多")}
                <br />
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </Flex>
        </Flex>
      ),
    },
    "corporate-and-academic-research-institutions": {
      title: t("LLMs企业/学术研究机构"),
      content: (
        <Flex vertical>
          <ContentFlex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("增强你的数据集")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("比竞争对手更完备")}</b> <br />
                {t("扫描件也可以成为模型可阅读的数据集")}
                <br />
                {t("古早文档统统收入囊中")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_1}></ContentImage>
          </ContentFlex>
          <ContentFlex>
            <ContentImage order={9} src={map_detail_img_2}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>{t("有效=高效")}</ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                <b>{t("只做有效的事")}</b> <br />
                {t("节省时间、财力")}
                <br />
                {t("把耗时耗力的重复性工作安心交给我们处理")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </ContentFlex>
        </Flex>
      ),
      docTitle:t("PDF扫描件文字提取利器，一键提升企业与学术文献处理效率"),
      d:t("PDFtoPDF文字提取利器，可用于快速摘录文献、报纸、杂志上的重要信息，进行引用或创作，提高研究和写作效率。支持将手稿或旧文档数字化，方便存储和检索，是LLMs企业和学术研究机构的得力助手！"),
      k:"convert pdf to text，handwriting into text，free online ocr，ocr for pdf documents，optical character scanner，pdf to pdf ocr"
    },
    "e-book-enterprise": {
      title: t("电子书企业"),
      content: (
        <Flex vertical>
          <ContentFlex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("降低95%成本")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                {t("快速促进书籍、档案数字化")}
                <br />
                {t("节省存储、传输")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_3}></ContentImage>
          </ContentFlex>
          <ContentFlex>
            <ContentImage order={9} src={map_detail_img_4}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>
                {t("更好服务用户")}
              </ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("给用户最快速提供最高清的图书")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </ContentFlex>
        </Flex>
      ),
      docTitle:t("高效提取PDF扫描件文本——电子书企业必备工具"),
      d:t("利用先进的OCR技术，PDFtoPDF网站能精准提取PDF扫描件中的文字内容，助力电子书企业快速将纸质书籍、文档转变为可编辑的文本，实现数字化飞跃。简化编辑流程，提升出版效率，为您的电子书项目注入无限创意与灵活性。立即体验，开启智慧出版新时代。"),
      k:"OCR technology，PDFtoPDF， extract text content from PDF scans，free online ocr"
    },
    "readers": {
      title: t("阅读爱好者"),
      content: (
        <Flex vertical>
          <ContentFlex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("降低95%成本")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("节省存储成本")}</b>
                <br />
                {t("小小的移动设备容纳更多书籍")}
                <br />
                {t("再也不必担心存储空间")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_5}></ContentImage>
          </ContentFlex>
          <ContentFlex>
            <ContentImage order={9} src={map_detail_img_4}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>{t("对自己更好")}</ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("清晰的阅读，美好的体验")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </ContentFlex>
        </Flex>
      ),
      docTitle:t("PDF扫描件图片文本一键提取：从图片到清晰文字，升级阅读爱好者体验"),
      d:t("专为阅读爱好者设计，这款OCR工具能巧妙地从PDF扫描件中抓取图片内的文字，将其转化为清晰、易读的文本格式。从此，不再受限于扫描文档的阅读不便，享受如纸质书般的流畅与清晰，让每一次阅读都成为一种享受。提升文本质量，尽享无界阅读新乐趣，现在就加入我们，开启您的舒适阅读之旅。"),
      k:"OCR tool，captures text from PDF scans ，scanning documents，pdf to pdf conversion，pdf convert to word converter"
    },
    "students-and-teachers": {
      title: t("学生/老师"),
      content: (
        <Flex vertical>
          <ContentFlex>
            <ContentTextBox>
              <ContentTextBoxTitle>
                {t("课件/文献语言障碍不复存在")}
              </ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("扫描件变文本件")}</b>
                <br />
                {t("直接翻译就好啦")}
                <br />
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_7}></ContentImage>
          </ContentFlex>
          <ContentFlex>
            <ContentImage order={9} src={map_detail_img_8}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>{t("DDL杀手")}</ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("快速复制引用，论文无忧")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </ContentFlex>
        </Flex>
      ),
      docTitle:t("教育助力：PDF扫描件文本轻松提取，优化学习资料，提升教学体验"),
      d:t("专为学生和教师打造的OCR解决方案，高效从PDF扫描件及图片中精准提取文本，转化成清晰可编辑的内容，让繁琐的学习资料瞬间变得井井有条。无论是论文研读、讲义整理还是教材制作，都能显著提升效率，助力学术研究与课堂教学迈上新台阶。立即体验，开启智慧学习新篇章。"),
      k:"OCR，extracts text from PDF scans and images，PDF scans，images"
    },
    "disabled-people": {
      title: t("残障人士"),
      content: (
        <Flex vertical>
          <ContentFlex>
            <ContentTextBox>
              <ContentTextBoxTitle>
                {t("让所有文件信息都能被读取")}
              </ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("扫描件转换文本后即可成为能够被语音阅读的文件")}</b>
                <br />
                {t("让所有文件变成无障碍")}
                <br />
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_9}></ContentImage>
          </ContentFlex>
          <ContentFlex>
            <ContentImage order={9} src={map_detail_img_10}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>
                {t("关心在身边的他/她们")}
              </ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("如果你希望为无障碍文件作出贡献")} <br />
                {t("非常欢迎联系我们")}
                <br />
                {t("我们将收录您的书籍，用于公益事业")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </ContentFlex>
        </Flex>
      ),
      docTitle:t("OCR文字识别技术：将PDF扫描件转化为语音可读文本，构筑无障碍信息桥梁"),
      d:t("利用先进的Text Recognition OCR技术，将PDF扫描文档轻松转换成可编辑、可语音阅读的文本，打破了残障人士阅读障碍。无论是学习资料、工作文件还是日常资讯，都能瞬间转化，让信息获取不再受限，促进数字内容的全面无障碍。"),
      k:"Text Recognition OCR technology，PDF scanned documents，ocr"
    },
    "language-learners": {
      title: t("语言学习者"),
      content: (
        <Flex vertical>
          <ContentFlex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("快速提升语言能力")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("扫描件变文本件")}</b>
                <br />
                {t("直接翻译就好啦")}
                <br />
                {t("多种语言自由切换阅读，不必再浪费过多时间查阅")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_11}></ContentImage>
          </ContentFlex>
          <ContentFlex>
            <ContentImage order={9} src={map_detail_img_12}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>
                {t("你可以学习更多")}
              </ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("看见更多语种，看见更多可能")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </ContentFlex>
        </Flex>
      ),
      docTitle:t("OCR技术赋能语言学习：PDF扫描件秒变文本，多语言翻译随心阅"),
      d:t("OCR文本提取 & 翻译神器：一键将PDF扫描文档转换成可编辑文本，无论是外语教材还是原版文献，都能即扫即译。支持多种语言切换，大大节省查阅时间，多语言阅读无障碍，学习加速新体验！"),
      k:"OCR Text Extraction，PDF scanned documents，convert into editable text，ocr"
    },
  };

  return (
    <>
      {withMeta({
        'title': contentDictionary[id ?? "0"].docTitle,
        description: contentDictionary[id ?? "0"].d,
        keywords:
        contentDictionary[id ?? "0"].k,
      })}
      <Layout style={{ background: "#FCF6E8", alignItems: "center" }}>
        <HomeHeader />
        <Content>
          <Title>{contentDictionary[id ?? "0"].title}</Title>
          {isMobile() ? <Line /> : null}
          {contentDictionary[id ?? "0"].content}

          {isMobile() ? (
            <UploadButton onClick={() => {fileInputRef.current?.click()}}>{t("上传文件")}</UploadButton>
          ) : (
            <MoreButton
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >
              {t("上传文件")}
            </MoreButton>
          )}
        </Content>
        <HomeFooter />
      </Layout>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </>
  );
}

const ContentFlex = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SizedBox = styled.div`
  width: 100px;
`;

const Line = styled.div`
  width: 1818px;
  height: 5px;
  background: #ebebeb;
  margin-top: 154px;
`;

const Content = styled.div`
  padding-left: 200px;
  padding-right: 200px;
  margin-top: 60px;
  margin-bottom: 100px;
  width: 1520px;
  height: 1269px;
  background: #ffffff;
  border-radius: 24px;

  @media only screen and (max-width: 768px) {
    width: 95%;
    height: 1269px;
    height: auto;
    margin-top: 0px;
    border-radius: 82px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Title = styled.div`
  margin-top: 60px;
  font-family: Montserrat Blod;
  font-weight: bold;
  font-size: 48px;
  color: #222222;
  line-height: 71px;
  text-align: center;
  font-style: normal;
  text-transform: none;

  @media only screen and (max-width: 768px) {
    font-size: 123px;
    line-height: 148px;
    margin-top: 154px;
  }
`;
const ContentTextBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 102px;
    padding-right: 102px;
    align-items: start;
    margin-top: 154px;
  }
`;

const ContentTextBoxEnd = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 102px;
    padding-right: 102px;
    align-items: center;
    margin-top: 154px;
  }
`;

const ContentTextBoxTitle = styled.div`
  font-family: Montserrat Blod;
  font-weight: bold;
  font-size: 42px;
  color: #222222;
  line-height: 62px;
  text-align: left;
  font-style: normal;
  white-space: nowrap;

  @media only screen and (max-width: 768px) {
    font-size: 113px;
    line-height: 138px;
    white-space: normal;
  }
`;

const ContentTextBoxTitleEnd = styled.div`
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 42px;
  color: #222222;
  line-height: 62px;
  text-align: end;
  font-style: normal;
  white-space: nowrap;

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 113px;
    line-height: 138px;
    white-space: normal;
    text-align: start;
  }
`;

const ContentTextBoxTxt = styled.div`
  margin-top: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  line-height: 48px;
  text-align: left;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 77px;
    line-height: 123px;
    margin-top: 42px;
  }
`;

const ContentTextBoxTxtEnd = styled.div`
  margin-top: 24px;
  font-family: Montserrat Regular;
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  line-height: 48px;
  text-align: end;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 77px;
    line-height: 123px;
    white-space: normal;
    text-align: start;
  }
`;

const ContentImage = styled.img<{ order?: number }>`
  width: 480px;
  height: 480px;

  @media only screen and (max-width: 768px) {
    width: 1229px;
    height: 1229px;
    order: ${(props) => (props.order ? props.order : 0)};
  }
`;

const MoreButton = styled.button`
  width: 420px;
  background: #fdc448;
  height: 68px;
  font-size: 24px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 16px;
  border: none;
  cursor: pointer;
`;

const UploadButton = styled.button`
  position: fixed;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 819px;
  background: #fdc448;
  height: 246px;
  font-size: 82px;
  box-shadow: 31px 31px 0px 0px #373737;
  border-radius: 41px;
  border: none;
  cursor: pointer;
  color: #333333;
`;