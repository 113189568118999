import React, { useEffect, useRef, useState } from "react";
import HomeHeader from "./Header/HomeHeader";
import {
  Button,
  Card,
  Flex,
  FloatButton,
  Layout,
  Space,
  Spin,
  message,
  Carousel
} from "antd";
import HomeFooter from "./Footer/HomeFooter";
import home_bg from "../../assets/home/home_bg_c@2x.png";
import home_mobile_bg from "../../assets/home/mobile/home_bg@2x (1).png";
import home_img_after from "../../assets/home/home_img_after@2x.png";
import home_img_before from "../../assets/home/home_img_before@2x.png";
import home_img_en_after from "../../assets/home/home_img1_after@2x.png";
import home_img_en_before from "../../assets/home/home_img1_before@2x.png";
import home_middle_img_1 from "../../assets/home/home_save_img_1@2x.png";
import home_middle_img_2 from "../../assets/home/home_save_img_2@2x.png";
import card_up_icon_9 from "../../assets/home/card_up_icon_9@2x.png";
import card_down_icon_9 from "../../assets/home/card_down_icon_9@2x.png";
import lottie_json_anim from "../../assets/home/data5.json";
import mobile_af from "../../assets/home/mobile/after_before@2x.png";
import bg_01 from "../../assets/home/bg01.png";
import bg_02 from "../../assets/home/bg02.png";
import bg_03 from "../../assets/home/bg03.png";
import mobile_bg_01 from "../../assets/home/mobile/why_bg@2x (3).png";
import mobile_bg_02 from "../../assets/home/mobile/why_bg_2@2x (3).png";
import mobile_bg_03 from "../../assets/home/mobile/why_bg_3@2x (3).png";
import home_img_why from "../../assets/home/home_img_why@2x.png";
import translate_icon from "../../assets/home/translate.png";
import arrow from "../../assets/home/arrow.png";
import styled from "styled-components";
import home_user from "../../assets/home/home_user@2x.png";
import feature1 from "../../assets/home/feature1.png";
import feature2 from "../../assets/home/feature2.png";
import feature3 from "../../assets/home/feature3.png";
import feature4 from "../../assets/home/feature4.png";
import feature5 from "../../assets/home/feature5.png";
import feature6 from "../../assets/home/feature6.png";
import autoOcr from "../../assets/home/autoOcr.png";
import mAndP from "../../assets/home/m&p.png";
import mAndPEn from "../../assets/home/m&p-en.png";
import howPdfToText from "../../assets/home/howPdfToText.png";
import scene1 from "../../assets/home/scene1.png";
import scene2 from "../../assets/home/scene2.png";
import scene3 from "../../assets/home/scene3.png";
import scene4 from "../../assets/home/scene4.png";
import scene5 from "../../assets/home/scene5.png";
import scene6 from "../../assets/home/scene6.png";
import pdfToText1 from "../../assets/home/pdfToText1.png";
import pdfToText2 from "../../assets/home/pdfToText2.png";
import pdfToText3 from "../../assets/home/pdfToText3.png";
import StyledDropzone from "./components/DropzoneInput";
import { useVoerkaI18n } from "@voerkai18n/react";
import { t } from "../../languages";
import { useNavigate } from "react-router-dom";
import { calculateLayoutWidth, isMobile, px2vw, mToPcPx } from "../../libs/utils";
import { Player } from "@lottiefiles/react-lottie-player";
import { useUpload } from "../../components/FileUploadContext";
import { logEvent } from "../../libs/analytics";
import OverlappingTitleBoxes from "./components/OverlappingTitleBoxes";
import CallBackBox from "./components/CallBackBox";
import withMeta from "../../components/withMeta";
import BlogBox from "./components/BlogBox";

export default function Home() {
  const { addFiles } = useUpload();

  const player = React.createRef<Player>();
  const targetRef = useRef<HTMLDivElement>(null); // 创建一个 ref 来引用目标元素
  const [isScrolledToElement, setIsScrolledToElement] =
    useState<boolean>(false);

  const [spinning, setSpinning] = React.useState<boolean>(false); // 全屏加载状态

  const { activeLanguage } = useVoerkaI18n();
  const navigate = useNavigate();

  const onFileDrop = async function (file: File) {
    addFiles(Array.from([file]));
    navigate("/historical");
    logEvent("Home", "file_upload", "upload");
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      logEvent("Home", "file_upload", "upload");
      addFiles(Array.from([file]));
      navigate("/historical");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (targetRef.current && !isScrolledToElement) {
        const { top } = targetRef.current.getBoundingClientRect();
        const isElementInView = top <= 600;
        setIsScrolledToElement(isElementInView);
        if (isElementInView) {
          player.current?.play();
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrolledToElement, setIsScrolledToElement, player]);

  return (
    <>
      {withMeta({
        title: t("免费在线OCR—将PDF扫描件转换为文本"),
        description: t(
          "通过ocr技术，将pdf扫描件转换为pdf文本文件，一键实现文件瘦身的同时保留文件原排版。阅读体验更佳，内容复制、搜索无阻碍!"
        ),
        keywords:
          "pdf to pdf conversion，pdf convert to word converter，ocr text recognition，image pdf to pdf converter，free online OCR，pdf smaller，ocr，ocr scanner",
      })}
      <Flex vertical>
        <Root>
          <HomeHeader />
          <Title>
            {activeLanguage === "zh" ? (
              <div
                style={{ fontWeight: "bold", fontFamily: "SourceHanSansCN" }}
              >
                Pdf扫描件到Pdf文本文件AI在线处理工具
              </div>
            ) : (
              <div>
                Convert the <b>PDF in the picture</b> to <br /> a <b>text</b>{" "}
                version with the <b>original layout</b>
              </div>
            )}
          </Title>
          <TagFlexBox>
            <OverlappingTitleBoxes label={t("高精OCR")} />
            <OverlappingTitleBoxes label={t("保留文件原排版")} />
            <OverlappingTitleBoxes label={t("文件压缩")} />
          </TagFlexBox>
          {/* 中间上传部分 */}
          {isMobile() ? (
            <Flex vertical>
              <TopImage
                src={activeLanguage === "zh" ? mobile_af : mobile_af}
                alt="before"
              />
              <ConvertImageText>{t("支持格式：PDF")}</ConvertImageText>
            </Flex>
          ) : (
            <Space
              size={calculateLayoutWidth(108)}
              style={{ marginTop: px2vw(54) }}
            >
              <Layout style={{ background: "transparent", width: px2vw(700) }}>
                <UploadRoot>
                  <TopImage
                    src={
                      activeLanguage === "zh"
                        ? home_img_before
                        : home_img_en_before
                    }
                    alt="before"
                  />
                  <StyledDropzone onFile={onFileDrop}></StyledDropzone>
                  <UploadTextBox>Before</UploadTextBox>
                </UploadRoot>

                <ConvertImageText>{t("支持格式：PDF")}</ConvertImageText>
              </Layout>
              <Layout style={{ background: "transparent", width: px2vw(700) }}>
                <UploadRoot>
                  <TopImage
                    src={
                      activeLanguage === "zh"
                        ? home_img_after
                        : home_img_en_after
                    }
                    alt="after"
                  />
                  <UploadTextBox>After</UploadTextBox>
                </UploadRoot>
                <ConvertImageText>{t("支持格式：PDF")}</ConvertImageText>
              </Layout>
            </Space>
          )}
        </Root>
        <Flex
          style={{ background: "#FFFFFF", display: "none" }}
          justify="center"
          align="center"
        >
          <CardMiddle>
            <Flex style={{ marginTop: px2vw(48) }} justify="center">
              <CardMiddleText ref={targetRef}>99%+</CardMiddleText>
              <Player
                ref={player}
                keepLastFrame={true}
                autoplay={false}
                loop={false}
                src={lottie_json_anim}
                style={{ width: px2vw(96), height: px2vw(96) }}
              />
            </Flex>
            <CardMiddleText2>{t("精准度")}</CardMiddleText2>
            <HomeUser src={home_user} alt="" />
          </CardMiddle>
        </Flex>
        <Flex
          style={{ background: "#FFFFFF", paddingBottom: px2vw(90) }}
          justify="center"
          align="center"
          vertical
        >
          <WhyMiddleText>{t("为什么选择我们")}</WhyMiddleText>
          <WhyChooseFlexBox>
            <WhyMiddleBg01 src={isMobile() ? mobile_bg_01 : bg_01}>
              <WhyTopText>{t("读者")}</WhyTopText>
              <WhyImage src={home_img_why} />
              <WhyBottomText>{t("清晰愉悦的阅读体验")}</WhyBottomText>
            </WhyMiddleBg01>
            <WhyMiddleBg01 src={isMobile() ? mobile_bg_02 : bg_02}>
              <WhyTopText>{t("大语言模型")}</WhyTopText>
              <WhyImage src={home_img_why} />
              <WhyBottomText>
                {activeLanguage === "zh" ? (
                  t("格式就是注意力")
                ) : (
                  <div>
                    Format is <br />{" "}
                    <span style={{ fontFamily: "Montserrat Black" }}>
                      attention
                    </span>
                  </div>
                )}
              </WhyBottomText>
            </WhyMiddleBg01>
            <WhyMiddleBg01 src={isMobile() ? mobile_bg_03 : bg_03}>
              <WhyTopText>{t("创作者")}</WhyTopText>
              <WhyImage src={home_img_why} />
              <WhyBottomText>
                {t("复制粘贴的快感")}
                {/* The Joy of <br /> copy and paste */}
              </WhyBottomText>
            </WhyMiddleBg01>
          </WhyChooseFlexBox>
          <MiddleTitle style={{width:'80%'}}>{t("PDF格式转换工具主要特点")}</MiddleTitle>
          <FeatureBox>
            <FeatureItem>
              <FeatureImg src={feature1}></FeatureImg>
              {t("文字识别准确度高")}<br/>
              <span>{t("高精度OCR文字识别技术，")}<b>{t("高达99.5%")}</b>{t("的识别精确度让扫描件即刻能用，")}
              <b>{t("减少90%")}</b>{t("无效时间。目前可识别中文简体、英文，后续还会新增日语、德语等其他语言。")}
              </span>
            </FeatureItem>
            <FeatureItem>
              <FeatureImg src={feature2}></FeatureImg>
              {t("批量处理高效快捷")}<br/>
              <span>{t("支持一次性处理多张PDF扫描件，")}<b>{t("在线批量")}</b>{t("文字识别，一键批量格式转换，极大提高效率。")}</span>
            </FeatureItem>
            <FeatureItem>
              <FeatureImg src={feature3}></FeatureImg>
              {t("大语言模型")}<br/>
              <span><b>AI</b>{t("机器学习识别，准确度随着自动学习越来越高，识别速度快；识别经过加密处理，无需担心隐私。")}</span>
            </FeatureItem>
          </FeatureBox>
          <FeatureBox>
            <FeatureItem>
              <FeatureImg src={feature4}></FeatureImg>
              {t("保留文件原排版")}<br/>
              <span>{t("图片转文字？不够！还要")}<b>{t("保留格式")}</b>{t("！PDF在线格式转换工具让你从繁琐的逐字输入文本的工作中解放，同时摆脱人工比对原稿逐字校对、排版。")}
              </span>
            </FeatureItem>
            <FeatureItem>
              <FeatureImg src={feature5}></FeatureImg>
              {t("压缩PDF扫描件")}<br/>
              <span><b>{t("图片变文字")}</b>{t("，立刻节省95%存储空间，经测试90MB文件处理后可缩小至6MB，在高清化的同时有效释放接近94%的空间，把钱花在刀刃上，PDFtoPDF")}<b>{t("在线工具")}</b>{t("高效为您解决问题！")}</span>
            </FeatureItem>
            <FeatureItem>
              <FeatureImg src={feature6}></FeatureImg>
              {t("为出行减负")}<br/>
              <span>{t("摆脱纸质资料，随时随地打开手机即可阅读，给读者带来清晰愉悦的阅读体验。从PDF扫描件中提取文本，创建")}<b>{t("可搜索、可复制和可访问")}</b>{t("的内容，给创作者带来复制粘贴的快感！")}</span>
            </FeatureItem>
          </FeatureBox>
        </Flex>
        <AutoOcr>
          <div>
            <h5>{t("OCR自动识别PDF扫描件，转换结果高清")}</h5>
            <span>{t("只需将PDF扫描件上传，等待几秒，OCR将自动扫描并提取扫描件中的文字，一比一转换为更清晰的PDF文本文件，从而创建便于搜索、编辑和访问的内容。")}</span>
          </div>
          <AutoOcrImg src={autoOcr}/>
        </AutoOcr>
        <AutoOcr style={{background:'#fff'}}>
          {
            isMobile() ? (
              <>
                <div>
                  <h5>{t("支持移动端 & PC端")}</h5>
                  <span>{t("无论是手机上还是电脑上，都可以使用，直接通过网址进入，无需下载，方便快捷、不占内存！再也不用担心遇到临时有文件需要处理的情况啦，直接在手机移动端完成在线OCR识别，随时随地高效完成！")}</span>
                </div>
                <AutoOcrImg src={activeLanguage === 'zh' ? mAndP : mAndPEn} />
              </>
            ) : (
              <>
                <AutoOcrImg src={activeLanguage === 'zh' ? mAndP : mAndPEn} style={{marginRight: px2vw(100)}}/>
                <div>
                  <h5>{t("支持移动端 & PC端")}</h5>
                  <span>{t("无论是手机上还是电脑上，都可以使用，直接通过网址进入，无需下载，方便快捷、不占内存！再也不用担心遇到临时有文件需要处理的情况啦，直接在手机移动端完成在线OCR识别，随时随地高效完成！")}</span>
                </div>
              </>
            )
          }
        </AutoOcr>
        { 
          !isMobile() && 
          <Flex
          style={{ background: "#FFF" }}
          justify="center"
          align="center"
          >
            <MoreButton
              style={{marginTop: 0, marginBottom:px2vw(14), width: px2vw(300)}}
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >{t("上传文件")}
            </MoreButton>
          </Flex>
        }
        <Flex
          style={{ background: "#FFF" }}
          justify="center"
          align="center"
          vertical
        >
          <MiddleTitle>{t("使用场景")}</MiddleTitle>
        </Flex>
        { !isMobile() &&
          <CustomCarousel arrows  style={{ width:'78%' }}>
            <Scene>
              <div className="item">
                <img src={scene1} alt="" />
                <div className="info">
                  {t("学生与老师")}<br/>
                  <span>{t("学生可以使用OCR技术将教材、笔记或研究资料中的文字快速提取出来，便于查找、整理学习材料或制作电子笔记。教师可以扫描讲义、参考书籍，通过OCR转换成文本，用于制作PPT、在线课程资料等。")}</span>
                </div>
              </div>
              <div className="item">
                <img src={scene2} alt="" />
                <div className="info">
                  {t("做出海业务的企业")}<br/>
                  <span>{t("企业出海业务合同扫描件经OCR技术，一键转换为PDF文本文件，提升信息录入速度与准确性，便于后期翻译，让您快速了解合同内容，支持搜索查找重要条款，增强跨国合同管理的效率与便利性。")}</span>
                </div>
              </div>
            </Scene>
            <Scene>
              <div className="item">
                <img src={scene3} alt="" />
                <div className="info">
                  {t("电子书企业")}<br/>
                  <span>{t("电子书企业使用OCR工具从扫描的文档、书籍、杂志等纸质材料中提取文字，快速实现纸质内容数字化，提升扫描件内容的可访问性、增强用户体验。")}</span>
                </div>
              </div>
              <div className="item">
                <img src={scene4} alt="" />
                <div className="info">
                  {t("阅读爱好者")}<br/>
                  <span>{t("经常需要外出或习惯于用电子设备阅读的朋友，使用OCR在线工具将准备看的书扫描为电子文档，随时随地皆可阅读。还可以根据个人喜好调整电子文本布局、字体和颜色，营造个性化阅读环境。更重要的是彻底让我们摆脱传统厚重的纸质书本，最大程度节省空间、减轻负重。")}</span>
                </div>
              </div>
            </Scene>
            <Scene>
              <div className="item">
                <img src={scene5} alt="" />
                <div className="info">
                  {t("语言学习者")}<br/>
                  <span>{t("OCR工具为语言学习者开启了一扇通往更广阔学习资源的大门，不仅能够将纸质材料瞬间转化为可编辑的电子文本，便于翻译、听写练习及个性化学习资料的创建，还可促进跨文化交流和学术研究的深化。")}</span>
                </div>
              </div>
              <div className="item">
                <img src={scene6} alt="" />
                <div className="info">
                  {t("残障人士")}<br/>
                  <span>{t("OCR技术为残障人士，尤其是视觉障碍者和阅读困难者，架起了通往信息自由和平等获取的桥梁。它将纸质材料瞬间转化为可听、可视的电子格式，满足多样化阅读需求，助力残障人士自主学习、高效沟通，极大地提升了他们在教育、工作和社会生活中的参与度与独立性。通过OCR，我们共同构建了一个更加包容和无障碍的世界。")}</span>
                </div>
              </div>
            </Scene>
          </CustomCarousel>
        }
        { isMobile() &&
          <CustomCarousel  style={{ width:'80%', marginBottom: mToPcPx(60)}}>
            <div className="m-item">
              <img src={scene1} alt="" />
              <div className="info">
                {t("学生与老师")}<br/>
                <span>{t("学生可以使用OCR技术将教材、笔记或研究资料中的文字快速提取出来，便于查找、整理学习材料或制作电子笔记。教师可以扫描讲义、参考书籍，通过OCR转换成文本，用于制作PPT、在线课程资料等。")}</span>
              </div>
            </div>
            <div className="m-item">
              <img src={scene2} alt="" />
              <div className="info">
                {t("做出海业务的企业")}<br/>
                <span>{t("企业出海业务合同扫描件经OCR技术，一键转换为PDF文本文件，提升信息录入速度与准确性，便于后期翻译，让您快速了解合同内容，支持搜索查找重要条款，增强跨国合同管理的效率与便利性。")}</span>
              </div>
            </div>
            <div className="m-item">
              <img src={scene3} alt="" />
              <div className="info">
                {t("电子书企业")}<br/>
                <span>{t("电子书企业使用OCR工具从扫描的文档、书籍、杂志等纸质材料中提取文字，快速实现纸质内容数字化，提升扫描件内容的可访问性、增强用户体验。")}</span>
              </div>
            </div>
            <div className="m-item">
              <img src={scene4} alt="" />
              <div className="info">
                {t("阅读爱好者")}<br/>
                <span>{t("经常需要外出或习惯于用电子设备阅读的朋友，使用OCR在线工具将准备看的书扫描为电子文档，随时随地皆可阅读。还可以根据个人喜好调整电子文本布局、字体和颜色，营造个性化阅读环境。更重要的是彻底让我们摆脱传统厚重的纸质书本，最大程度节省空间、减轻负重。")}</span>
              </div>
            </div>
            <div className="m-item">
              <img src={scene5} alt="" />
              <div className="info">
                {t("语言学习者")}<br/>
                <span>{t("OCR工具为语言学习者开启了一扇通往更广阔学习资源的大门，不仅能够将纸质材料瞬间转化为可编辑的电子文本，便于翻译、听写练习及个性化学习资料的创建，还可促进跨文化交流和学术研究的深化。")}</span>
              </div>
            </div>
            <div className="m-item">
              <img src={scene6} alt="" />
              <div className="info">
                {t("残障人士")}<br/>
                <span>{t("OCR技术为残障人士，尤其是视觉障碍者和阅读困难者，架起了通往信息自由和平等获取的桥梁。它将纸质材料瞬间转化为可听、可视的电子格式，满足多样化阅读需求，助力残障人士自主学习、高效沟通，极大地提升了他们在教育、工作和社会生活中的参与度与独立性。通过OCR，我们共同构建了一个更加包容和无障碍的世界。")}</span>
              </div>
            </div>
          </CustomCarousel>
        }
        <Flex
          style={{ background: "#FFF" }}
          justify="center"
          align="center"
          vertical
        >
          {
            !isMobile() &&
            <MoreButton
              style={{marginTop: 0, marginBottom:px2vw(122), width: px2vw(300)}}
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >{t("上传文件")}
            </MoreButton>
          }
        </Flex>
        <Flex
          style={{ background: "#FDC448" }}
          justify="center"
          align="center"
          vertical
        >
          <MiddleTitle>{t("我们是为节省成本而来的")}</MiddleTitle>
          <MiddleContent>
            <MiddleLabelBox>
              <SizedBox height={24} />
              <MiddleLabelContent1>
                {t("减少90%无效时间")} <MiddleLabelImg src={card_down_icon_9} />
              </MiddleLabelContent1>
              <SizedBox height={24} />
              <MiddleLabelBlock />
              <SizedBox height={24} />
              {t("不必再人工逐字逐句输入、校对、排版")} <br />
              <b>{t("高达99.5%的识别精确度让扫描件即刻能用")}</b>
              <SizedBox height={20} />
              {t("图片转文字？不够！")} <br />
              <SizedBox height={20} />
              {t("还要保留格式！我们只做最佳转换器！")} <br />
              {t("从此告别转文字后比对着原稿一行一行辛苦排版工作")} <br />
              <SizedBox height={20} />
              <MiddleLabelContent2>
                {t(
                  "实验数据：PDF Pro服务将通过 LLM 纠错，将文本识别精度提升至99.5%"
                )}
                <br />
              </MiddleLabelContent2>
              <SizedBox height={24} />
            </MiddleLabelBox>
            <MiddleImage src={home_middle_img_1} />
          </MiddleContent>

          <MiddleContent>
            {isMobile() ? null : <MiddleImage src={home_middle_img_2} />}
            <SizedBox width={200} />
            <MiddleLabelBox>
              <SizedBox height={24} />
              <MiddleLabelContent1>
                {t("节省95%存储成本")} <MiddleLabelImg src={card_up_icon_9} />
              </MiddleLabelContent1>
              <SizedBox height={24} />
              <MiddleLabelBlock />
              <SizedBox height={24} />
              {t("图片变成文字")} <br />
              {t("立刻节省95%存储空间")} <br />
              {t("让钱花去刀刃上，我们来解决问题")} <br />
              <SizedBox height={24} />
              <MiddleLabelContent2>
                {t(
                  "实验数据：90MB文件处理后缩小至6MB，在高清化的同时有效释放接近94%的空间"
                )}
                <br />
              </MiddleLabelContent2>
              <SizedBox height={24} />
            </MiddleLabelBox>
            {!isMobile() ? null : <MiddleImage src={home_middle_img_2} />}
          </MiddleContent>
          <SizedBox height={100} />
        </Flex>
        <Flex
          style={{background: "#FFFFFF"}}
          justify="center"
          align="center"
          vertical
        >
          {
            isMobile() ?
              (
                <>
                  <MiddleTitle>{t("如何将PDF扫描件转换为PDF文本？")}</MiddleTitle>
                  <MPdfToText>
                    <span>1</span>
                    {t("上传PDF扫描件")}
                    <img src={pdfToText1} alt="" />
                    <span>2</span>
                    {t("OCR识别和提取")}
                    <p style={{top:'20%',right: 0}}>{t("通过OCR技术")}<b>{t("快速")}</b>{t("识别pdf扫描件内容，提取结果精准度高达")}<b>{t("99%＋")}</b></p>
                    <img src={pdfToText2} alt="" />
                    <span>3</span>
                    {t("下载")}
                    <p style={{top:'10%'}}>{t("PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件，便于查找内容、节省存储空间、阅读体验更佳")}</p>
                    <img src={pdfToText3} alt="" />
                  </MPdfToText>
                </>
              )
            :
              (
                <>
                  <MiddleTitle>{t("如何将PDF扫描件转换为PDF文本？")}</MiddleTitle>
                  <HowPdfToText>
                    <div className="step" style={{top: "14%", left: "20%"}}>
                      <span>1</span>
                      {t("上传PDF扫描件")}
                    </div>
                    <div className="step" style={{top: "46%", left:activeLanguage === 'zh' ? "58%" : "38%"}}>
                      <span>2</span>
                      {t("OCR识别和提取")}
                      <p style={{top:'90%',right: 0,textAlign:'right'}}>{t("通过OCR技术")}<b>{t("快速")}</b>{t("识别pdf扫描件内容，提取结果精准度高达")}<b>{t("99%＋")}</b></p>
                    </div>
                    <div className="step" style={{top: "76%", left: "20%"}}>
                      <span>3</span>
                      {t("下载")}
                      <p style={{top:'90%',textAlign:'left'}}>{t("PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件，便于查找内容、")}
                        <br/>{t("节省存储空间、阅读体验更佳")}
                      </p>
                    </div>
                  </HowPdfToText>
                </>
              )
          }
          
        </Flex>
        <CallBackBox />
        <Flex
          style={{
            background: "#FFFFFF",
            paddingBottom: px2vw(90),
          }}
          justify="center"
          align="center"
          vertical
        >
          <WhyMiddleText>{t("我们想说")}</WhyMiddleText>
          <BlogSized />
          <BlogBox />

          <MoreButton
            onClick={() => {
              navigate("/articles");
              logEvent("Home", "articles_click", "articles");
            }}
          >
            {t("更多")}
            <MoreIcon alt="" src={arrow} />
          </MoreButton>
        </Flex>
        <HomeFooter />
      </Flex>
      <Spin spinning={spinning} fullscreen tip={t("正在上传")} />
      <FloatButton
        onClick={() => {
          VoerkaI18n.change(activeLanguage === "zh" ? "en" : "zh");
        }}
        icon={
          // eslint-disable-next-line jsx-a11y/alt-text
          <img
            style={{ width: 20, objectFit: "cover" }}
            src={translate_icon}
          ></img>
        }
      />
      {isMobile() && <UploadButton onClick={() => {fileInputRef.current?.click()}}>{t("上传文件")}</UploadButton>}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background-size: cover;
  background: url(${home_bg}) top center;

  @media only screen and (max-width: 768px) {
    background: url(${home_mobile_bg}) top center;
  }
`;

const Title = styled.div`
  margin-top: 32px;
  @media only screen and (max-width: 768px) {
    width: 1715px;
    height: auto;
    font-family: Montserrat Light;
    font-weight: 300;
    font-size: 174px;
    color: #222222;
    line-height: 215px;
    text-align: center;
    font-style: normal;
    text-transform: none;

    b {
      font-family: Montserrat Bold;
      font-size: 174px;
    }
  }

  @media only screen and (min-width: 769px) {
    width: 1520px;
    height: auto;
    font-family: Montserrat Light;
    font-weight: 300;
    font-size: 70px;
    color: #222222;
    line-height: 86px;
    text-align: center;
    font-style: normal;
    text-transform: none;

    b {
      font-family: Montserrat Bold;
      font-size: 84px;
    }
  }
`;

const TagFlexBox = styled.div`
  display: flex;
  gap: 108px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 108px;
  }

  @media only screen and (min-width: 769px) {
    flex-direction: row;
    margin-top: 54px;
  }
`;

const WhyChooseFlexBox = styled.div`
  display: flex;
  gap: 120px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
`;

const UploadRoot = styled.div`
  width: 700px;
  position: relative;
`;

const UploadTextBox = styled.text`
  min-width: 92px;
  position: absolute;
  right: 26px;
  top: 26px;
  background: #fdc448;
  border-radius: 4px;
  padding: 8px;
  font-weight: 600;
  font-size: 24px;
  color: #222222;
  line-height: 33px;
  text-align: center;
  font-style: normal;
`;

const ConvertImageText = styled.text`
  margin-top: 34px;
  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  line-height: 33px;
  text-align: center;
  font-style: normal;
  margin-bottom: 80px;

  @media only screen and (max-width: 768px) {
    font-size: 72px;
    margin-top: 72px;
    margin-bottom: 108px;
  }
`;

const CardMiddle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 100px;
  align-items: center;
  width: 600px;
  height: 356px;
  background: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 24px;
`;

const CardMiddleText = styled.div`
  width: 274px;
  height: 96px;
  background: #fd9416;
  border-radius: 48px;

  font-family: Montserrat;
  font-weight: bold;
  font-size: 78px;
  color: #ffffff;
  line-height: 96px;
  text-align: center;
  font-style: normal;
`;

const CardMiddleText2 = styled.div`
  font-family: Montserrat, Montserrat;
  font-weight: bold;
  font-size: 36px;
  color: #222222;
  line-height: 44px;
  text-align: center;
  font-style: normal;
  margin-top: 20px;
`;

const WhyMiddleText = styled.text`
  font-family: Montserrat Bold;
  font-weight: bold;
  font-size: 52px;
  color: #222222;
  line-height: 63px;
  text-align: right;
  font-style: normal;
  margin-top: 100px;
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    font-size: 154px;
    margin-top: 205px;
    margin-bottom: 102px;
  }
`;

const WhyMiddleBg01 = styled.div<{
  src?: string;
}>`
  background: ${(props) =>
    props.src ? `url(${props.src}) top center / 100% no-repeat` : ""};
  width: 400px;
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 1464px;
    height: 911px;
  }
`;

const WhyTopText = styled.text`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 53px;
  color: #222222;
  line-height: 64px;
  text-align: right;
  font-style: normal;
  margin-top: 132px;

  @media only screen and (max-width: 768px) {
    line-height: 169px;
    font-size: 135px;
    margin-top: 282px;
  }
`;

const WhyImage = styled.img`
  background: none;
  width: 240px;
  height: 13px;
  margin-top: 30px;

  @media only screen and (max-width: 768px) {
    width: 614px;
    height: 36px;
    margin-top: 72px;
  }
`;

const WhyBottomText = styled.text`
  width: 260px;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 32px;
  color: #222222;
  line-height: 44px;
  text-align: center;
  font-style: normal;
  margin-top: 30px;
  margin-bottom: 89px;

  @media only screen and (max-width: 768px) {
    line-height: 113px;
    font-size: 92px;
    margin-top: 72px;
    width: auto;
  }
`;

const TopImage = styled.img`
  width: 700px;

  @media only screen and (max-width: 768px) {
    margin-top: 102px;
    width: 1736px;
  }
`;

const HomeUser = styled.img`
  width: 274px;
  margin-top: 34px;
`;

const MoreButton = styled.button`
  border: none;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    margin-top: 205px;
    width: 512px;
    height: 205px;
    border-radius: 41px;
    color: #373737;
  }

  @media only screen and (min-width: 769px) {
    width: 512px;
    margin-top: 80px;
    height: 205px;
    border-radius: 41px;

    margin-top: 80px;
    background: #fdc448;
    width: 180px;
    height: 60px;
    font-size: 24px;
    box-shadow: 6px 6px 0px 0px #373737;
    border-radius: 16px;
  }
`;

const MoreIcon = styled.img`
  width: 10px;
  height: 18px;
  margin-left: 8px;

  @media only screen and (max-width: 768px) {
    width: 31px;
    height: 51px;
    margin-left: 20px;
  }
`;

const MiddleTitle = styled.label`
  margin-top: 95px;
  width: auto;
  font-family: Montserrat Bold;
  font-weight: bold;
  font-size: 52px;
  color: #222222;
  line-height: 77px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    width: 1420px;
    margin-top: 205px;
    line-height: 189px;
    font-size: 154px;
  }
`;

const MiddleContent = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  width: 1280px;
  min-height: auto;
  background: #ffffff;
  border-radius: 24px;
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 85%;
    flex-direction: column;
    margin-top: 102px;
  }
`;

const MiddleLabelBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: left;
  align-items: start;

  font-weight: 400;
  font-size: 20px;
  color: #222222;
  line-height: 38px;
  text-align: left;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 77px;
    line-height: 123px;
  }
`;

const MiddleLabelContent1 = styled.label`
  width: auto;
  font-family: SourceHanSansCN;
  font-weight: bold;
  font-size: 42px;
  color: #222222;
  line-height: 62px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 113px;
    line-height: 138px;
    width: 70%;
    text-align: left;
  }
`;

const MiddleLabelContent2 = styled.label`
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 18px;
  color: #666666;
  line-height: 27px;
  text-align: left;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    color: #999999;
    font-size: 61px;
    line-height: 113px;
    text-align: left;
  }
`;

const MiddleLabelBlock = styled.div`
  width: 48px;
  height: 8px;
  background: #222222;
  line-height: 36px;

  @media only screen and (max-width: 768px) {
    width: 123px;
    height: 20px;
  }
`;

const MiddleImage = styled.img`
  width: 480px;

  @media only screen and (max-width: 768px) {
    width: 1229px;
  }
`;

const SizedBox = styled.div<{
  width?: number;
  height?: number;
}>`
  width: ${(props) => (props.width ? props.width : 0)}px;
  height: ${(props) => (props.height ? props.height : 0)}px;

  @media only screen and (max-width: 768px) {
    width: ${(props) => (props.width ? props.width * 2 : 0)}px;
    height: ${(props) => (props.height ? props.height * 2 : 0)}px;
  }
`;

const MiddleLabelImg = styled.img`
  width: 48px;
  height: 48px;

  @media only screen and (max-width: 768px) {
    width: 123px;
    height: 123px;
  }
`;

const BlogSized = styled.div`
  height: 80px;
`;

const UploadButton = styled.button`
  position: fixed;
  bottom: 240px;
  left: 50%;
  transform: translateX(-50%);
  width: 819px;
  background: #fdc448;
  height: 246px;
  font-size: 82px;
  box-shadow: 31px 31px 0px 0px #373737;
  border-radius: 41px;
  border: none;
  cursor: pointer;
  color: #333333;
`;

const FeatureBox = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const FeatureItem = styled.div`
  margin: 60px 60px 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 400px;
  color: #222;
  font-size: 24px;
  font-weight: bold;
  font-family: Montserrat Bold;
  @media only screen and (max-width: 768px) {
    margin-bottom: 102px;
    align-items: center;
    width: 90%;
    font-size: 82px;
    text-align: center;
  }

  span {
    margin-top: 12px;
    font-size: 18px;
    font-weight: 500 !important;
    color: #666;
    font-family: '';
    @media only screen and (max-width: 768px) {
      font-size: 62px;
      line-height: 100px;
    }
  }
  b {
    color: #222;
  }
`;

const FeatureImg = styled.img`
  margin-bottom: 8px;
  width: 72px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
    width: 247px;
  }
`;

const AutoOcr = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 520px;
  color: #222;
  font-size: 20px;
  background: #FCF6E8;
  text-align: left;
  h5 {
    margin-bottom: 16px;
    margin-right: 100px;
    width: 640px;
    font-size: 36px;
    font-weight: bold;
    font-family: Montserrat Bold;
    @media only screen and (max-width: 768px) {
      margin: 0;
      margin-bottom: ${mToPcPx(8)};
      width: ${mToPcPx(335)};
      font-size: ${mToPcPx(18)};
    }
  }
  span {
    display: inline-block;
    width: 640px;
    line-height: 30px;
    @media only screen and (max-width: 768px) {
      margin-bottom: ${mToPcPx(20)};
      width: ${mToPcPx(335)};
      line-height: 120px;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: ${mToPcPx(480)};
    font-size: ${mToPcPx(14)};
  }
`;

const AutoOcrImg = styled.img`
  width: 540px;

  @media only screen and (max-width: 768px) {
    width: ${mToPcPx(300)};
  }
`;


const Scene = styled.div`
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  .item {
    display: inline-block;
    margin: 0 40px 0;
    width: 520px;
    height: 860px;
    background: #FFFFFF;
    border-radius: 24px;
    border: 1px solid #EBEBEB;
    img {
      width: 100%;
    }
    .info {
      padding: 30px 40px;
      color: #222;
      font-size: 28px;
      font-family: Montserrat Bold;
      text-align: left;
    }
    span {
      margin-top: 8px;
      font-size: 18px;
      font-weight: 500;
      font-family: '';
    }
  }
`;

const CustomCarousel = styled(Carousel)`
  position: relative;
  display: block;
  margin: 80px auto 128px;

  .slick-arrow {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background:rgba(0, 0, 0, 0.7);
    &::after {
      width: 30px;
      height: 30px;
      top: 35%;
    }
  }
  .slick-next {
    &::after {
      left: 26%;
    }
  }
  .slick-prev {
    &::after {
      left: 36%;
    }
  }
  .slick-dots {
    position: absolute;
    left: 50%;
    bottom: -5%;
    transform: translateX(-50%);
    button {
      background: #FDC448 !important;
    }
  }
  .m-item {
    display: inline-block;
    width: ${mToPcPx(240)};
    height: ${mToPcPx(500)};
    background: #FFFFFF;
    box-shadow: 0px 7px 44px 0px rgba(0,0,0,0.06);
    border-radius: 11px;
    border: 1px solid #EBEBEB;
    img {
      width: 100%;
    }
    .info {
      padding: ${mToPcPx(16)} ${mToPcPx(20)};
      color: #222;
      font-size: ${mToPcPx(16)};
      font-weight: bold;
      text-align: left;
    }
    span {
      margin-top: ${mToPcPx(8)};
      font-size: ${mToPcPx(12)};
      font-weight: 500;
    }
  }
`;

const HowPdfToText = styled.div`
  position: relative;
  margin: 80px 0;
  width: 1360px;
  height: 760px;
  background-image: url(${howPdfToText});
  background-size: cover;
  .step {
    position: absolute;
    display: flex;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    font-family: Montserrat Bold;
    /* white-space: nowrap; */
    span {
      margin-right: 10px;
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 50%;
      background: #FDC448;
    }
    p {
      position: absolute;
      width: 740px;
      font-size: 20px;
      font-weight: 500;
      /* white-space: nowrap; */
      font-family: '';
    }
  }
`;

const MPdfToText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${mToPcPx(20)} ${mToPcPx(40)};
  font-size: ${mToPcPx(18)};
  font-weight: bold;
  span {
    margin: ${mToPcPx(40)} 0 ${mToPcPx(20)};
    width: ${mToPcPx(36)};
    height: ${mToPcPx(36)};
    line-height: ${mToPcPx(36)};
    border-radius: 50%;
    background: #FDC448;
  }
  img {
    margin-top: ${mToPcPx(20)};
    width: ${mToPcPx(200)};
  }
  p {
    margin-top: ${mToPcPx(8)};
    line-height: ${mToPcPx(28)};
    font-size: ${mToPcPx(15)};
    font-weight: 500;
  }
`;

