import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import deviceuuid from "../deviceuuid";
import tokenManager from "../TokenManager";
import { message } from 'antd';
import { t } from '../../languages';
import { useAuth } from '../../components/AuthContext';
import { apiAnonymousUserLogin } from './api';

const uuid = deviceuuid();

// 获取当前地区
export const getRegion = async () => {
  const response = await fetch("https://ipapi.co/json/");
  var data = await response.json();
  return data;
};

export type Format<T> = {
  code: number;
  data: T;
  message: string;
};

export type FormatMessage<T> = {
  code: number;
  data: string;
  message: T;
};

/** 用以通过 api 函数获取返回值类型，使用 `AjaxResponse<typeof apiFunction>` 即可获取到 apiFunction 的返回类型  */
export type AjaxResponse<T extends (...params: any) => Promise<AxiosResponse<Format<any>, any>>> = Parameters<
  Exclude<Parameters<ReturnType<T>["then"]>[0], null | undefined>
>[0];

const defualtConfig: AxiosRequestConfig = {
  timeout: 6000000,
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "A-Language": "en",
    "Device-Type": "web",
    "Version-Code": "1.0.0",
    "Device-UUID": uuid,
    "Device-Model": "web",
    "Authorization": "Bearer " + tokenManager.getToken() ?? ""
  }
  // withCredentials: true,
};


// 创建一个 Axios 实例
const instance: AxiosInstance = axios.create(defualtConfig);



// 添加拦截器
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === 401 || error.response?.status === 422) {
      message.error(t("请先登录！"))

      const auth = useAuth()
      // auth.logout()

      // token 过期，尝试重新登录获取新 token
      try {
        const ip_data = await getRegion()
        const newTokenResponse = await apiAnonymousUserLogin({ language: tokenManager.getLanguage() ?? "en", device_fingerprint: deviceuuid() ,ip_address:ip_data.ip, country:ip_data.country })
        auth.isLocalLogin = true
        tokenManager.saveToken(newTokenResponse.data.data.access_token)
        error.config.headers.Authorization = `Bearer ${tokenManager.getToken()}`;
        // 重新发起之前失败的请求
        return instance(error.config);
      } catch (loginError) {
        console.log(loginError);
        // 处理登录失败的情况
        return Promise.reject(loginError);
      }
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  config => {
    // 尝试从 localStorage 获取 Token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;  // 添加 Token 到请求头
    }
    return config;
  },
  error => {
    // 请求错误处理
    return Promise.reject(error);
  }
)

export default instance;
