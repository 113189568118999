import { Collapse, CollapseProps, Flex, Space, Spin, message } from "antd";
import Layout, { Content } from "antd/es/layout/layout";
import HomeHeader from "../Home/Header/HomeHeader";
import HomeFooter from "../Home/Footer/HomeFooter";
import icon_upload from "../../assets/historical/icon_upload@2x.png";
import HistoricalItem, { Status } from "./components/HistoricalItem";
import { useEffect, useMemo, useRef, useState } from "react";
import { useRequest } from "ahooks";
import {
  TaskItemData,
  apiConfirmPayment,
  apiPdfTasks,
} from "../../libs/http/api";
import StyledDropzone from "./components/DropzoneInput";
import { useSearchParams } from "react-router-dom";
import React from "react";
import { t } from "../../languages";
import { isMobile, px2vw } from "../../libs/utils";
import { useAuth } from "../../components/AuthContext";
import { useUpload } from "../../components/FileUploadContext";
import { logEvent } from "../../libs/analytics";
import withMeta from "../../components/withMeta";
import styled from "styled-components";

function useApi() {
  const auth = useAuth();
  const [updateTask, setUpdateTask] = useState<TaskItemData>();
  let [taskList, setTaskList] = useState(Array<TaskItemData>);
  const tasks = useRequest(apiPdfTasks, { manual: true }); // 获取制作任务合集
  const { runAsync: getTasks } = tasks;
  const [loading, setLoading] = useState(false);

  const payment_confirm = useRequest(apiConfirmPayment, { manual: true }); // 订单支付确认
  const { runAsync: payment } = payment_confirm;

  const onUpdateCompleted = async () => {
    var resp = await getTasks();
    if (resp.data.code === 200) {
      resp.data.data.reverse();
      setTaskList([...resp.data.data]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (auth.isLoggedIn || auth.isLocalLogin) {
      setLoading(true);
      onUpdateCompleted();
    }
  }, [auth.isLoggedIn, auth.isLocalLogin]);

  return {
    tasks,
    taskList,
    updateTask,
    setUpdateTask,
    getTasks,
    setTaskList,
    payment,
    onUpdateCompleted,
    loading,
  };
}

export default function Historical() {
  const { addFiles, fileStatuses, setListener, clearFile } = useUpload();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [spinning, setSpinning] = React.useState<boolean>(false); // 全屏加载状态
  const api = useApi();
  let [searchParams] = useSearchParams();
  const paymentIntentId = searchParams.get("payment_intent");

  useEffect(() => {
    const listener = {
      onFileUpload: async (fileName: string) => {
        await api.onUpdateCompleted();
        clearFile(fileName);
      },
    };

    setListener(listener);
  }, []);

  useEffect(() => {
    if (paymentIntentId) {
      setSpinning(true);
      const confirmPayment = async () => {
        try {
          const response = await api.payment(paymentIntentId);
          if (response.data.code === 200) {
            logEvent(
              "Home",
              "pay_success",
              paymentIntentId,
              response.data.data.amount
            );
            searchParams.set("payment_intent", "");
            window.history.replaceState(
              {},
              document.title,
              window.location.pathname
            ); // 清空传递参数
            setTimeout(() => {
              message.success(response.data.message);
              api.onUpdateCompleted();
              setSpinning(false);
            }, 1000);
          } else {
            setTimeout(confirmPayment, 1000); // 如果状态码不是 200，则继续请求
          }
        } catch (error) {
          console.error("请求出错：", error);
          setSpinning(false);
          searchParams.set("payment_intent", "");
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          ); // 清空传递参数
        }
      };

      confirmPayment();
    }
  }, []);

  const onFileDrop = function (file: File) {
    addFiles(Array.from([file]));
    logEvent("Historical", "file_upload", "upload");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      logEvent("Home", "file_upload", "upload");
      addFiles(Array.from([file]));
    }
  };

  return (
    <>
      {withMeta({
        title: t("更多Pdf文档ocr文本提取处理记录"),
        description: t("点击查看历史处理记录。"),
        keywords:
          "pdf to pdf conversion，handwriting to text，pdf to pdf ocr，ocr pdf to pdf",
      })}
      <Layout style={{ background: "#FCF6E8", alignItems: "center" }}>
        <HomeHeader />

        <ContentStyle>
          <Title>{t("处理记录")}</Title>
          <ItemFlex>
            { (api.taskList.length === 0 || !isMobile()) && (
              <StyledDropzone onFile={onFileDrop} />
            )}

            {fileStatuses.map((fileStatus, index) => (
              <HistoricalItem
                key={fileStatus?.file?.name + fileStatus.status + index}
                data={fileStatus}
                onUpdateCompleted={api.onUpdateCompleted}
              ></HistoricalItem>
            ))}
            {api.loading ? (
              <Spin style={{ marginTop: px2vw(60) }} size="large" /> // 显示加载中的提示，可以调整Spin的属性如size
            ) : (
              api.taskList.map((item) => (
                <HistoricalItem
                  key={item.pdf_id + item.status} // 唯一key，确保列表渲染正确
                  data={item}
                  onUpdateCompleted={api.onUpdateCompleted}
                ></HistoricalItem>
              ))
            )}
          </ItemFlex>
        </ContentStyle>

        <HomeFooter />
      </Layout>
      {(isMobile() && api.taskList.length > 0) && (
        <UploadButton
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          {t("上传文件")}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </UploadButton>
      )}
      <Spin spinning={spinning} fullscreen />
    </>
  );
}

const ContentStyle = styled.div`
  width: 1520px;
  min-height: 76vh;
  text-align: center;
  justify-content: center;
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    width: 90%;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: 48px;
  font-family: Montserrat Bold;
  @media only screen and (max-width: 768px) {
    font-size: 123px;
  }
`;

const ItemFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 40px;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    margin-top: 200px;
    gap: 60px;
  }
`;

const UploadButton = styled.button`
  position: fixed;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 819px;
  background: #fdc448;
  height: 246px;
  font-size: 82px;
  box-shadow: 31px 31px 0px 0px #373737;
  border-radius: 41px;
  border: none;
  cursor: pointer;
  color: #333333;
`;
