// InviteZhImageCanvas.tsx
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import QRCode from "qrcode";
import { t } from "../languages";

const InviteZhImageCanvas: React.FC<{
  imageSrc: string;
  text: string;
  url: string;
}> = ({ imageSrc, text, url }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = async () => {
        canvas.width = img.width;
        canvas.height = img.height;
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          ctx.font = "32px canvas-kh";
          ctx.fillStyle = "#fa3334";
          ctx.fillText(text, 500, 795);
          ctx.font = "36px canvas-kh";
          ctx.fillText(text, 140, 4410);

          QRCode.toDataURL(url, { margin: 1, width: 160 }, (err, url) => {
            if (err) {
              console.error(err);
              return;
            }
            const qrImg = new Image();
            qrImg.src = url;

            qrImg.onload = () => {
              ctx.drawImage(qrImg, 490, 4370); // 设置绘制二维码的位置
            };
          });
        }
      };

      img.src = imageSrc;
    }
  }, [imageSrc]);

  const downloadImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const image = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      const fakeLink = window.document.createElement("a");
      fakeLink.style.display = "none";
      fakeLink.download = "pdftopdf_share.png";

      fakeLink.href = image;

      document.body.appendChild(fakeLink);
      fakeLink.click();
      document.body.removeChild(fakeLink);

      fakeLink.remove();
    }
  };

  return (
    <div>
      <div
        style={{
          overflowY: "auto",
          maxHeight: "80vh",
          fontFamily: "canvas-kh",
        }}
      >
        <canvas ref={canvasRef} />
      </div>
      <SaveButton onClick={downloadImage}>{t("保存至本地")}</SaveButton>
    </div>
  );
};

export default InviteZhImageCanvas;

const QrBackgroud = styled.div`
  width: 140px;
  height: 140px;
  background-color: white;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

const SaveButton = styled.button`
  margin-top: 50px;
  border: 0;
  width: 420px;
  height: 68px;
  background: #fdc448;
  border-radius: 8px;

  font-weight: bold;
  font-size: 24px;
  color: #222222;
  line-height: 36px;
  text-align: center;
  font-style: normal;

  cursor: pointer;
`;
