import instance from "./http/http";

class TokenManager {

  private static instance: TokenManager;

  private constructor() { } // 私有构造函数，防止外部创建实例

  static getInstance(): TokenManager {
    if (!TokenManager.instance) {
      TokenManager.instance = new TokenManager();
    }
    return TokenManager.instance;
  }

  saveGoogleToken(token: string) {
    localStorage.setItem('google', token);
  }

  saveToken(token: string) {
    localStorage.setItem('token', token);
  }

  saveEmail(email: string) {
    localStorage.setItem('email', email);
  }

  saveEmailPwd(pwd: string) {
    localStorage.setItem('email_pwd', pwd);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  getGoogleToken(): string | null {
    return localStorage.getItem('google');
  }

  getEmail(): string | null {
    return localStorage.getItem('email');
  }

  getEmailPwd(): string | null {
    return localStorage.getItem('email_pwd');
  }

  getLanguage(): string | null {
    return localStorage.getItem('language');
  }

  removeToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('google');
    localStorage.removeItem('email')
    localStorage.removeItem('email_pwd')
    const interc = instance.interceptors.request.use((config) => {
      config.headers.Authorization = "";
      return config;
    });
    window.location.reload();
  }
}

export default TokenManager.getInstance(); // 导出单例实例
