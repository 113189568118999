// InviateModel.tsx 邀请弹窗
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import bg from "../assets/inv_model_bg.jpg";
import share_image_zh from "../assets/home/invite_zh_bg.jpg";
import share_image_en from "../assets/home/share_img_en.png";
import { t } from "../languages";
import html2canvas from "html2canvas";
import { QRCode } from "antd";
import { useSafeState } from "ahooks";
import { QRCodeSVG } from "qrcode.react";
import { calculateLayoutWidth, px2vw } from "../libs/utils";
import InviteZhImageCanvas from "../components/InviteZhImageCanvas";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  activeLanguage: string;
  qrstring: string;
  code: string;
}

const InviateModel: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  activeLanguage,
  qrstring,
  code,
}) => {
  const [qrSize, setQrsize] = useSafeState(140);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      // 当窗口大小变化时，更新状态
      setQrsize((140 * window.innerWidth) / 1920);
    };

    // 添加事件监听器
    window.addEventListener("resize", handleResize);

    // 清理函数，在组件卸载时移除监听器
    return () => window.removeEventListener("resize", handleResize);
  }, []); // 空依赖数组意味着这个效果只会在组件挂载和卸载时运行

  const saveDivAsImage = async () => {
    if (divRef.current) {
      const canvas = await html2canvas(divRef.current, {
        backgroundColor: null,
        scale: 1.5,
      });

      const image = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      downloadImage(image, "pdftopdf_share.png");
    }
  };

  const downloadImage = (blob: string, fileName: string) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style.display = "none";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  return (
    <>
      <Overlay isOpen={isOpen} onClick={onClose}>
        {activeLanguage === "en" ? (
          <div>
            <ModalContent ref={divRef}>
              <UrlLabel>pdftopdf.ai</UrlLabel>
              <Title>
                {/* {activeLanguage === "zh" ? (
                <b>一键还原书本原版格式</b>
              ) : (
                <div>
                  Convert the{" "}
                  <b>
                    PDF in the <br /> picture
                  </b>{" "}
                  to a <b>text </b>version <br />
                  with the <b>original layout</b>
                </div>
              )} */}
                <div>
                  Convert the{" "}
                  <b>
                    PDF in the <br /> picture
                  </b>{" "}
                  to a <b>text </b>version <br />
                  with the <b>original layout</b>
                </div>
              </Title>
              <ContentImage
                src={share_image_en}
                // src={activeLanguage === "zh" ? share_image_zh : share_image_en}
              />
              <QrBackgroud>
                <QRCodeSVG
                  width={calculateLayoutWidth(120)}
                  height={calculateLayoutWidth(120)}
                  value={qrstring}
                  type="svg"
                ></QRCodeSVG>
              </QrBackgroud>

              <TipsLabel>
                {t("扫描二维码注册")} <br /> {t("获得100页PDF Pro处理次数")}
              </TipsLabel>
            </ModalContent>
            <SaveButton onClick={saveDivAsImage}>{t("保存至本地")}</SaveButton>
          </div>
        ) : (
          <InviteZhImageCanvas
            imageSrc={share_image_zh}
            text={"【" + code + "】"}
            url={qrstring}
          />
        )}
      </Overlay>
    </>
  );
};

const Overlay = styled.div<{ isOpen: boolean }>`
  flex-direction: column;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); // 灰色背景蒙层
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 440px;
  height: 770px;
  position: relative;
  background: url(${bg});
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UrlLabel = styled.label`
  margin-top: 26px;
  width: 200px;
  height: 54px;
  background: #ffffff;
  border-radius: 10px;

  font-weight: bold;
  font-size: 22px;
  color: #ff7a07;
  text-align: center;
  line-height: 54px;
`;

const Title = styled.div`
  margin-top: 22px;
  width: 100%;
  height: auto;
  font-family: Montserrat Light;
  font-weight: 300;
  font-size: 28px;
  color: #ffffff;
  line-height: 39px;
  text-align: center;
  font-style: normal;

  b {
    font-family: Montserrat Bold;
    font-size: 34px;
  }
`;

const ContentImage = styled.img`
  margin-top: 16px;
  width: 100%;
`;

const TipsLabel = styled.label`
  margin-top: 16px;
  width: 90%;
  height: auto;
  font-weight: 400;
  font-size: 18px;
  color: #222222;
  line-height: 27px;
  text-align: center;
  font-style: normal;
  text-transform: none;

  font-family: Montserrat Light;
`;

const SaveButton = styled.button`
  margin-top: 50px;
  border: 0;
  width: 420px;
  height: 68px;
  background: #fdc448;
  border-radius: 8px;

  font-weight: bold;
  font-size: 24px;
  color: #222222;
  line-height: 36px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
`;

const QrBackgroud = styled.div`
  width: 140px;
  height: 140px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

const ImageContainer = styled.img`
  max-height: 80vh; /* 控制最大高度 */
  overflow-y: auto; /* 允许垂直滚动 */
`;

export default InviateModel;
