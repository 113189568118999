import {
  Layout,
  Flex,
  Button,
  Space,
  Modal,
  Spin,
  Popover,
  message,
} from "antd";
import { t } from "../../../languages";
import logo from "../../../assets/logo@2x.png";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { isMobile, px2vw } from "../../../libs/utils";
import { StatusChangeInfo, useAuth } from "../../../components/AuthContext";
import imgGoogle from "../../../assets/home/login_icon_google@2x.png";
import imgEmail from "../../../assets/home/login_icon_email@2x.png";
import imgEdit from "../../../assets/home/ocr_icon_edit@2x.png";
import ocrUserIcon from "../../../assets/home/ocr_user@2x.png";
import GoogleLogin from "react-google-login";
import { useRequest, useSafeState } from "ahooks";
import InviateBar from "./InviateBar";
import {
  apiCheckEmail,
  apiSendEmail,
  apiSetPassword,
} from "../../../libs/http/api";
import InviateCodeModel from "../../../components/InviateCodeModel";
import { logEvent } from "../../../libs/analytics";
import { useVoerkaI18n } from "@voerkai18n/react";
import PopoverUseScene from "./PopoverUseScene";
import PopoverBlog from "./PopoverBlog";
import { MenuOutlined } from "@ant-design/icons";
import HeaderDrawer from "./HeaderDrawer";
import h5_open from "../../../assets/home/mobile/h5_nav_menu@2x.png"
import emitter from "../../../libs/eventBus";

enum LoginStatus {
  Page,
  EmailLogin,
  EmailPwd,
  EmailSendCode,
  EmailSetPwd,
}

export default function HomeHeader() {
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("invite_code"); // 邀请码
  const officialInviteCode = searchParams.get("official_invite_code"); // 邀请码

  const navigate = useNavigate();
  const [isMobileOpen, setIsMobileOpen] = useSafeState(false); //移动端页面更多按钮
  const [isPopInvDialog, setPopInvDialog] = useSafeState(false); //退出账号按钮
  const [isPopLogout, setisPopLogout] = useSafeState(false); //退出账号按钮
  const [isModalOpen, setModalOpen] = useSafeState(false); // 登录弹窗展示
  const [spinning, setSpinning] = useSafeState(false); // 加载态
  const [logingLoading, setLogingLoading] = useSafeState(false); //登录中状态
  const auth = useAuth(); //全局登录态
  const { activeLanguage } = useVoerkaI18n();
  const [isScrolled, setIsScrolled] = useState(false);
  const [layout, setLayout] = useState<LoginStatus>(LoginStatus.Page);
  const [canEmailInput, setEmailInput] = useState<boolean>(true); // 邮箱是否可以输入
  const [email, setEmail] = useState(""); // 邮箱
  const [code, setCode] = useState(""); // 邮箱验证码
  const [password, setPassword] = useState(""); // 邮箱密码

  const checkApi = useRequest(apiCheckEmail, { manual: true });
  const sendApi = useRequest(apiSendEmail, { manual: true });
  const setPwdApi = useRequest(apiSetPassword, { manual: true });
  const inputRef = useRef<HTMLInputElement>(null);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    emitter.on('login', onLoginClick);
    if (officialInviteCode && auth.isLoggedIn === false) {
      onLoginClick();
    }
    return () => {
      emitter.off('login', onLoginClick);
    };
  }, []);

  useEffect(() => {
    
    if (auth) {
      //监听登录中状态
      const handleStatusChange = (info: StatusChangeInfo) => {
        setLogingLoading(info.isLoggedLoading);
      };

      // 添加监听器
      auth.addStatusChangeListener(handleStatusChange);

      // 组件卸载时移除监听器
      return () => {
        auth.removeStatusChangeListener(handleStatusChange);
      };
    }
  }, [auth]);

  const toHistorical = function () {
    navigate("/historical");
    logEvent("Home", "historical_click", "historical");
  };

  const onLoginClick = () => {
    if (auth.isLoggedIn) {
      onLogoutClick();
      return;
    }

    setModalOpen(true);
    logEvent("Home", "login_click", "login");
  };

  const onLogoutClick = () => {
    setisPopLogout(false);
    auth.logout();
    logEvent("Home", "logut_click", "logout");
  };

  const onGoogleLogin = async (resp: any) => {
    if (resp !== undefined) {
      setSpinning(true);
      setModalOpen(false);
      var id_token = resp.tokenId as string;
      await auth.loginGoogle(id_token, inviteCode, officialInviteCode);
      setSpinning(false);
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setisPopLogout(newOpen);
  };

  const showInviteDialog = () => {
    setPopInvDialog(true);
    logEvent("Home", "Click", "invite dialog");
  };

  const checkEmail = async () => {
    const resp = await checkApi.runAsync(email);
    if (resp.data.data.has_registered) {
      setEmailInput(false);
      setLayout(LoginStatus.EmailPwd);
    } else {
      setLayout(LoginStatus.EmailSendCode);
      sendApi.runAsync(email, activeLanguage ?? "en");
    }
  };

  const register = async () => {
    const resp = await auth.registerEmail(
      email,
      code,
      password,
      inviteCode,
      officialInviteCode
    );
    if (resp) {
      modelClose();
    } else {
      message.error(t("验证码错误！"));
    }
  };

  const loginWithEmail = async () => {
    const resp = await auth.loginWithEmail(email, password);
    if (resp) {
      modelClose();
    }
  };

  const modelClose = () => {
    setLayout(LoginStatus.Page);
    setModalOpen(false);
    setEmailInput(true);
    setPassword("");
    setCode("");
    setEmail("");
  };

  const renderLayout = () => {
    switch (layout) {
      case LoginStatus.Page:
        return (
          <RenderLayoutStyle>
            <LoginTitle>{t("登录")}</LoginTitle>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_KEY ?? ""}
              buttonText="Login"
              onSuccess={onGoogleLogin}
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => (
                <GoogleLoginBtn
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <span>
                    {t("谷歌一键登录")}
                    <img alt="google" src={imgGoogle} />
                  </span>
                </GoogleLoginBtn>
              )}
            ></GoogleLogin>
            <GoogleLoginBtn
              onClick={() => {
                setLayout(LoginStatus.EmailLogin);
              }}
            >
              <span>
                {t("邮箱登录")}
                <img alt="google" src={imgEmail} />
              </span>
            </GoogleLoginBtn>
          </RenderLayoutStyle>
        );
      case LoginStatus.EmailLogin:
        return (
          <RenderLayoutStyle>
            <LoginTitle>{t("邮箱登录")}</LoginTitle>
            <InputWrapper>
              <EmailInput
                ref={inputRef}
                disabled={!canEmailInput}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={t("请输入邮箱")}
              ></EmailInput>
              {!canEmailInput && (
                <InputIcon
                  onClick={() => {
                    setEmailInput(true);
                  }}
                  src={imgEdit}
                />
              )}
            </InputWrapper>
            <SumbitButtom onClick={checkEmail}>{t("确认")}</SumbitButtom>
          </RenderLayoutStyle>
        );
      case LoginStatus.EmailPwd:
        return (
          <RenderLayoutStyle>
            <LoginTitle>{t("邮箱登录")}</LoginTitle>
            <InputWrapper>
              <EmailInput
                ref={inputRef}
                disabled={!canEmailInput}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={t("请输入邮箱")}
              ></EmailInput>
              {!canEmailInput && (
                <InputIcon
                  onClick={() => {
                    setEmailInput(true);
                  }}
                  src={imgEdit}
                />
              )}
            </InputWrapper>
            <SizeBox></SizeBox>
            <InputWrapper>
              <EmailInput
                type="password"
                datatype="passworedtype"
                placeholder={t("请输入密码")}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              ></EmailInput>
            </InputWrapper>
            <SumbitButtom onClick={loginWithEmail}>{t("确认")}</SumbitButtom>
          </RenderLayoutStyle>
        );
      case LoginStatus.EmailSendCode:
        return (
          <RenderLayoutStyle>
            <LoginTitle>{t("验证码已发送至邮箱")}</LoginTitle>
            <SizeBox></SizeBox>
            <EmailInput
              key={"code"}
              placeholder={t("请输入验证码")}
              onChange={(data) => {
                setCode(data.target.value);
              }}
            ></EmailInput>
            <SizeBox></SizeBox>
            <EmailInput
              key={"set_pwds"}
              type="password"
              datatype="passworedtype"
              placeholder={t("设置密码")}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            ></EmailInput>
            <SumbitButtom onClick={register}>{t("确认")}</SumbitButtom>
          </RenderLayoutStyle>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Flex style={{ width: "100%" }} vertical>
        <HeaderStyle isScrolled={isScrolled}>
          {auth.isLoggedIn && !isMobile() ? <InviateBar /> : null}
          <HeaderBar>
            <HeaderImage onClick={() => navigate("/")} src={logo} alt="logo" />
            <div style={{ width: "100%" }} />
            {!isMobile() ? (
              <div>
                {!logingLoading ? (
                  <Flex gap={50} align="center" justify="center">
                    <LinkButton
                      onClick={() => {
                        navigate("/pdf-to-pdf-conversion");
                      }}
                    >
                      {t("图书转文字")}
                    </LinkButton>
                    <PopoverUseScene>
                      <LinkButton>{t("使用场景")}</LinkButton>
                    </PopoverUseScene>
                    <LinkButton
                      onClick={() => {
                        navigate("/get-api");
                      }}
                    >
                      {t("API")}
                    </LinkButton>
                    <LinkButton
                      onClick={() => {
                        navigate("/faq");
                      }}
                    >
                      {t("Q&A")}
                    </LinkButton>
                    <PopoverBlog>
                      <LinkButton
                        onClick={() => {
                          navigate("/articles");
                        }}
                      >{t("Blog")}</LinkButton>
                    </PopoverBlog>
                    <LinkButton
                      onClick={() => {
                        navigate("/pricing");
                      }}
                    >
                      {t("定价")}
                    </LinkButton>
                    <LinkButton
                      onClick={() => {
                        navigate("/historical");
                      }}
                    >
                      {t("处理记录")}
                    </LinkButton>
                    {!auth.isLoggedIn ? (
                      <LinkButton
                        onClick={() => {
                          showInviteDialog();
                        }}
                      >
                        {t("填写邀请码")}
                      </LinkButton>
                    ) : null}

                    {auth.isLoggedIn ? (
                      <Flex justify="center" align="center">
                        <CountSapn>
                          {t("可处理页书：")}
                          {auth.count}
                        </CountSapn>
                        <Popover
                          placement="bottom"
                          content={
                            <a
                              onClick={onLogoutClick}
                              style={{ color: "#333333" }}
                            >
                              {t("退出账号")}
                            </a>
                          }
                          trigger="click"
                          open={isPopLogout}
                          onOpenChange={handleOpenChange}
                        >
                          <img
                            alt=""
                            src={ocrUserIcon}
                            style={{ width: px2vw(50), height: px2vw(50) }}
                          ></img>
                        </Popover>
                      </Flex>
                    ) : (
                      <LoginButton onClick={onLoginClick}>
                        {t("登录")}
                      </LoginButton>
                    )}
                  </Flex>
                ) : (
                  <Spin size="large" />
                )}
              </div>
            ) : (
              <div>
                <DreawerOpen
                  src={h5_open}
                  onClick={() => {
                    setIsMobileOpen(!isMobileOpen);
                  }}
                />
              </div>
            )}
          </HeaderBar>
        </HeaderStyle>
      </Flex>

      <Modal
        width={450}
        open={isModalOpen}
        onCancel={() => {
          modelClose();
        }}
        footer={null}
      >
        {renderLayout()}
      </Modal>
      <InviateCodeModel
        isOpen={isPopInvDialog}
        onClose={(success: boolean, code?: string) => {
          setPopInvDialog(false);
          if (success && auth.isLoggedIn === false) {
            navigate("/register?official_invite_code=" + code);
          }
        }}
      ></InviateCodeModel>
      <Spacer isLogin={auth.isLoggedIn}></Spacer>
      <HeaderDrawer
        onLogin={onLoginClick}
        open={isMobileOpen}
        onClose={() => {
          setIsMobileOpen(false);
        }}
        onInvClick={() => {
          showInviteDialog();
        }}
      />
      <Spin spinning={spinning} fullscreen tip={t("正在登录")} />
    </>
  );
}

const RenderLayoutStyle = styled.div`
  height: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    height: 1200px;
    gap: 42px;
  }
`;

const Spacer = styled.div<{ isLogin: boolean }>`
  @media only screen and (max-width: 768px) {
    height: 471px;
  }

  @media only screen and (min-width: 769px) {
    height: ${(props) =>
      props.isLogin ? "9vw" : "4.5vw"}; /* 设置与header相同的高度 */
  }
`;

const HeaderBar = styled.div`
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: center;
`;

const HeaderImage = styled.img`
  width: 280px;
  height: 90px;

  @media only screen and (max-width: 768px) {
    width: 988px;
    height: 317px;
  }

  @media only screen and (min-width: 769px) {
    width: 280px;
    height: 90px;
  }

  cursor: pointer;
`;

const HeaderStyle = styled.header<{ isScrolled: boolean }>`
  width: 100%;
  position: fixed;
  top: 0;

  box-shadow: ${(props) =>
    props.isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none"};
  z-index: 1000;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    background-color: white;
    min-height: 317px;
  }

  @media only screen and (min-width: 769px) {
    background-color: ${(props) =>
      props.isScrolled ? "white" : "transparent"};
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    min-height: 88px;
  }
`;

const LinkButton = styled.div`
  flex-basis: 150px;
  background: none;
  border: 0;
  width: auto;
  font-weight: 400;
  font-size: 20px;
  color: #222222;
  line-height: 29px;
  text-align: center;
  font-style: normal;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

const HeaderUl = styled.ul`
  display: flex;
  padding: 4px 10px;
  font-size: 16px;

  li {
    display: flex;
    padding: 4px 10px;
    font-size: 16px;
    border: 1px solid red;
  }
  li::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    visibility: hidden;
    height: 1px;
    color: transparent;
    margin-bottom: -1px;
    overflow: hidden;
  }
  li:hover {
    font-weight: bold;
  }
`;

const LoginButton = styled.button`
  cursor: pointer;
  margin-left: 12px;
  height: 46px;
  min-width: 100px;
  background: #222222;
  border-radius: 8px;

  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  line-height: 29px;
  text-align: center;
  font-style: normal;

  border: 0;
`;

const LoginTitle = styled.label`
  margin-top: 50px;
  margin-bottom: 30px;
  height: 54px;
  font-weight: bold;
  font-size: 36px;
  color: #222222;
  line-height: 54px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 92px;
    margin-bottom: 100px;
  }
`;

const GoogleLoginBtn = styled.button`
  cursor: pointer;
  width: 90%;
  height: 68px;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #ebebeb;
  margin-top: 30px;

  span {
    position: relative;
    font-weight: bold;
    font-size: 22px;
    color: #303133;
    line-height: 36px;
    text-align: center;
    font-style: normal;
  }

  img {
    position: absolute;
    top: -4px;
    left: -40px;
    width: 32px;
    height: 32px;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    height: 206px;
    background: #ffffff;
    border-radius: 20px;
    border: 5px solid #ebebeb;

    img {
      top: -24px;
      left: -140px;
      width: 123px;
      height: 123px;
    }

    span {
      font-size: 64px;
      line-height: 92px;
    }
  }
`;

const CountSapn = styled.label`
  margin-right: 30px;
  width: auto;
  min-width: 150px;
  font-weight: 500;
  font-size: 20px;
  color: #222222;
  line-height: 29px;
  text-align: center;
  font-style: normal;
`;

const EmailInput = styled.input`
  width: 388px;
  height: 44px;
  border-radius: 8px;
  border: 2px solid #ebebeb;

  padding: 16px;

  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  font-style: normal;

  ::placeholder {
    color: #b6b6b6;
  }

  :disabled {
    background-color: #f0f0f0; /* 灰色背景 */
    color: #999999; /* 灰色文字 */
    border: 1px solid #cccccc; /* 边框颜色 */
    cursor: not-allowed; /* 禁用时的光标 */
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 200px;
    background: #ffffff;
    border-radius: 20px;
    border: 5px solid #ebebeb;

    font-size: 72px;
    line-height: 92px;
  }
`;

const SumbitButtom = styled.button`
  cursor: pointer;
  margin-top: 50px;
  background: #fdc448;
  width: 420px;
  height: 60px;
  font-size: 24px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    width: 90%;
    height: 246px;
    font-size: 82px;
    color: #222222;
    line-height: 97px;
    border-radius: 41px;
    box-shadow: 20px 20px 0px 0px #373737;
  }
`;

const InputWrapper = styled.div`
  width: 90%;
  position: relative;
  display: inline-block;
`;

const InputIcon = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 16px;
  cursor: pointer;
`;

const SizeBox = styled.div`
  height: 30px;
`;

const DreawerOpen = styled.img`
  width: 246px;
  height: 246px;

  cursor: pointer;
`;