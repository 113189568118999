import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Layout, Flex, Button, Space, Popover } from "antd";
import { t } from "../../../languages";
import { isMobile, px2vw, mToPcPx } from "../../../libs/utils";
import { useVoerkaI18n } from "@voerkai18n/react";
import { useNavigate, useNavigation } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../assets/footer_logo@2x.png";
import facebook from "../../../assets/home/facebook@2x.png";
import twitter from "../../../assets/home/twitter@2x.png";
import linkedin from "../../../assets/home/linkedin@2x.png";
import producthunt from "../../../assets/home/producthunt@2x.png";


const { Footer } = Layout;

const beian = {
  "score.leqiai.cn": "苏ICP备14019013号-24",
  "hearscore.cn": "沪ICP备19013413号-7",
  "www.hearscore.cn": "沪ICP备19013413号-7",
  "hearthescore.com": "沪ICP备19013413号-5",
  "www.hearthescore.com": "沪ICP备19013413号-5",
};

const HomeFooter = forwardRef<HTMLElement, ComponentPropsWithoutRef<"footer">>(
  (props, ref) => {
    const navigate = useNavigate();

    return (
      <Flex
        vertical
        style={{
          width: "100%",
          textAlign: "center",
          background: "#222222",
          color: "#8f8f8f",
        }}
      >
        <Content>
          <ContentFlex>
            <List>
              <LiStyle
                onClick={() => {
                  navigate("/historical");
                }}
              >
                {t("PDF到PDF的转化")}
              </LiStyle>
              <LiStyle
                onClick={() => {
                  navigate("/pdf-to-pdf-conversion");
                }}
              >
                {t("图片转文字")}
              </LiStyle>
              <LiStyle
                onClick={() => {
                  navigate(
                    "/users/corporate-and-academic-research-institutions"
                  );
                }}
              >
                {t("使用场景")}
              </LiStyle>
              <LiStyle
                onClick={() => {
                  navigate("/faq");
                }}
              >
                {t("常见问题")}
              </LiStyle>
            </List>
            {isMobile() ? null : <SizedBox width={320}></SizedBox>}
            <List>
              <LiStyle
                onClick={() => {
                  navigate("/pricing");
                }}
              >
                {t("定价")}
              </LiStyle>
              <LiStyle
                onClick={() => {
                  navigate("/articles");
                }}
              >
                {t("博客")}
              </LiStyle>
              {/* <LiStyle>{t("在线客服")}</LiStyle>
                <LiStyle>{t("用户反馈")}</LiStyle> */}
            </List>
          </ContentFlex>

          <LogoBox>
            <Logo src={logo}></Logo>
            <div className="media-list">
              <a href="https://www.facebook.com/profile.php?id=61559838135770"><img src={facebook} alt="" /></a>
              <a href="https://x.com/pdftopdf"><img src={twitter} alt="" /></a>
              <a href="https://www.linkedin.com/in/%E5%B3%A5-%E9%BE%99-584bb2308/"><img src={linkedin} alt="" /></a>
              <a href="https://www.producthunt.com/@pdftopdf"><img src={producthunt} alt="" /></a>
            </div>
            <span className="email">{t("邮箱：")}pdftopdf@leqi.ai</span>
          </LogoBox>
        </Content>
        <div
          style={{
            width: "100%",
            opacity: "0.2",
            height: "1px",
            background: "white",
          }}
        />
        <FooterLabel>{new Date().getFullYear()} Resource</FooterLabel>
      </Flex>
    );
  }
);

export default HomeFooter;

const Content = styled.div`
  height: 324px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media only screen and (max-width: 768px) {
    height: 2560px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
`;

const ContentFlex = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: start;
  }
`;

const LogoBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  /* margin-left: 340px; */
  /* height: 60px; */

  @media only screen and (max-width: 768px) {
    width: 937px;
    height: 184px;
    /* order: -1; */
    margin-left: 120px;
    margin-bottom: 94px;
  }

  .media-list {
    position: relative;
    margin-top: 10px;
    /* margin-left: 320px; */
    display: flex;
    img {
      display: block;
      margin-right: 30px;
      width: 40px;
      height: 40px;
      @media only screen and (max-width: 768px) {
        width: ${mToPcPx(20)} !important;
        height: ${mToPcPx(20)} !important;
      }
    }
  }
  .email {
    /* position: absolute;
    left: 16%;
    bottom: -80%; */
    /* margin-left: -100px;
    margin-top: 10px; */
    margin-top: 30px;
    color: #fff;
    font-size: 18px;
    text-align: left;
    @media only screen and (max-width: 768px) {
      font-size: ${mToPcPx(14)};
    }
  }
`;

const Logo = styled.img`
  width: 305px;
  height: 60px;

  @media only screen and (max-width: 768px) {
    width: 937px;
    height: 184px;
  }
`;

const SizedBox = styled.div<{
  width?: number;
  height?: number;
}>`
  width: ${(props) => (props.width ? props.width : 0)}px;
  height: ${(props) => (props.height ? props.height : 0)}px;
`;

const LiStyle = styled.li`
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  line-height: 56px;
  text-align: left;
  font-style: normal;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    line-height: 287px;
    font-size: 82px;
  }
`;

const FooterLabel = styled.div`
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  @media only screen and (max-width: 768px) {
    height: 200px;
    line-height: 92px;
    font-size: 72px;
  }
`;

const List = styled.ul`
  list-style-type: none;

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding-left: 120px;
  }
`;
