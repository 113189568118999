// src/analytics.ts
import ReactGA from 'react-ga';

const TRACKING_ID = 'G-6EGBCQBMP9'; // 替换成你的跟踪ID
ReactGA.initialize(TRACKING_ID);

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const logEvent = (category: string, action: string, label?: string, value?: number) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value
  });
};
