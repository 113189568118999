import React from "react";
import { Button, Popover } from "antd";
import styled from "styled-components";
import icon_llms from "../../../assets/home/home_use_icon_llms@2x.png";
import home_use_icon_company from "../../../assets/home/home_use_icon_company@2x.png";
import home_use_icon_read from "../../../assets/home/home_use_icon_read@2x.png";
import home_use_icon_student from "../../../assets/home/home_use_icon_student@2x.png";
import home_use_icon_body from "../../../assets/home/home_use_icon_body@2x.png";
import home_use_icon_ab from "../../../assets/home/home_use_icon_ab@2x.png";
import { t } from "../../../languages";
import { useNavigate } from "react-router-dom";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const GridItem = styled.div`
  width: 300px;
  height: 120px;
  text-align: center;
  display: flex;
  border-radius: 8px;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background: #fdc448;
  }
`;

const Image = styled.img`
  margin-top: 24px;
  margin-left: 20px;
  width: 36px;
  height: 36px;
`;

const Label = styled.label`
  margin-top: 8px;
  margin-left: 20px;

  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 20px;
  color: #222222;
  line-height: 29px;
  text-align: left;
  font-style: normal;
`;

const PopoverUseScene: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {

    var nav = useNavigate()

  const content = (
    <GridContainer>
      <GridItem onClick={()=> {nav("/users/corporate-and-academic-research-institutions")}}>
        <Image src={icon_llms} />
        <Label>{t("LLMs企业/学术研究机构")}</Label>
      </GridItem>

      <GridItem onClick={()=> {nav("/users/e-book-enterprise")}}>
        <Image src={home_use_icon_company} />
        <Label>{t("电子书企业")}</Label>
      </GridItem>

      <GridItem onClick={()=> {nav("/users/readers")}}>
        <Image src={home_use_icon_read} />
        <Label>{t("阅读爱好者")}</Label>
      </GridItem>

      <GridItem onClick={()=> {nav("/users/students-and-teachers")}}>
        <Image src={home_use_icon_student} />
        <Label>{t("学生/老师")}</Label>
      </GridItem>

      <GridItem onClick={()=> {nav("/users/disabled-people")}}>
        <Image src={home_use_icon_body} />
        <Label>{t("残障人士")}</Label>
      </GridItem>

      <GridItem onClick={()=> {nav("/users/language-learners")}}>
        <Image src={home_use_icon_ab} />
        <Label>{t("语言学习者")}</Label>
      </GridItem>
    </GridContainer>
  );

  return <Popover content={content}>{children}</Popover>;
};

export default PopoverUseScene;
