import Layout from "antd/es/layout/layout";
import HomeFooter from "../Home/Footer/HomeFooter";
import HomeHeader from "../Home/Header/HomeHeader";
import { apiArticleList } from "../../libs/http/api";
import { useRequest } from "ahooks";
import styled from "styled-components";
import { Flex } from "antd";
import { isMobile, px2vw } from "../../libs/utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useVoerkaI18n } from "@voerkai18n/react";
import { t } from "../../languages";
import { useUpload } from "../../components/FileUploadContext";
import React, { useRef } from "react";
import withMeta from "../../components/withMeta";

export interface ContentItem {
  title: string;
  content: JSX.Element;
}

export interface ContentDictionary {
  [key: string]: ContentItem;
}

export default function Pricing() {
  const { addFiles } = useUpload();
  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      addFiles(Array.from([file]));
      navigate("/historical");
    }
  };

  return (
    <>
      {withMeta({
        title: t("Pdf to Pdf| 高效工具全方位定价方案 | 量身定制满足您的需求"),
        description: t(
          "探索Pdf to Pdf软件的灵活定价计划，体验无与伦比的功能，享受个性化服务，现在注册更有超值优惠！"
        ),
        keywords:
          "file conversion package，converter free online，pdf to pdf conversion，ocr text recognition，scanner for text",
      })}
      <Layout style={{ background: "#FCF6E8", alignItems: "center" }}>
        <HomeHeader />
        <Title>{t("定价")}</Title>
        <SizedBox height={40}></SizedBox>
        <ContentFlex>
          <Content>
            <TopLeft>{t("推荐")}</TopLeft>
            <ContentBox>
              <ProTop>PDF Pro</ProTop>
              <SizedBox height={50}></SizedBox>
              <ContentLabel>{t("99.5%识别正确率")}</ContentLabel>
              <SizedBox height={24}></SizedBox>
              <ContentLabelSmall>{t("S$0.07/页")}</ContentLabelSmall>
              <SizedBox height={40}></SizedBox>
              <ContentLabel>{t("适合专业人士/企业/机构")}</ContentLabel>
              <SizedBox height={24}></SizedBox>
              <ContentLabelSmall>
                {t("尽可能高性价比 尽可能高效")}
              </ContentLabelSmall>
              <SizedBox height={24}></SizedBox>
              <ContentLabelSmall>
                {t("让大语言模为您智能纠错")}
              </ContentLabelSmall>
            </ContentBox>
          </Content>

          <SizedBox width={96}></SizedBox>
          <ContentBox>
            <NorTop>OCR</NorTop>
            <SizedBox height={50}></SizedBox>
            <ContentLabel>{t("99%识别正确率")}</ContentLabel>
            <SizedBox height={24}></SizedBox>
            <ContentLabelSmall>{t("S$0.05/页")}</ContentLabelSmall>
            <SizedBox height={40}></SizedBox>
            <ContentLabel>{t("适合对精确度要求不高的个人")}</ContentLabel>
            <SizedBox height={24}></SizedBox>
            <ContentLabelSmall>{t("尽可能低廉")}</ContentLabelSmall>
            <SizedBox height={24}></SizedBox>
            <ContentLabelSmall>{t("满足日常需求")}</ContentLabelSmall>
          </ContentBox>
        </ContentFlex>

        {isMobile() ? (
            <UploadButton onClick={() => {fileInputRef.current?.click()}}>{t("上传文件")}</UploadButton>
          ) : (
            <MoreButton
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >
              {t("上传文件")}
            </MoreButton>
          )}

        <HomeFooter />
      </Layout>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </>
  );
}

const SizedBox = styled.div<{
  width?: number;
  height?: number;
}>`
  width: ${(props) => (props.width ? props.width : 0)}px;
  height: ${(props) => (props.height ? props.height : 0)}px;

  @media only screen and (max-width: 768px) {
    width: ${(props) => (props.width ? props.width * 3 : 0)}px;
    height: ${(props) => (props.height ? props.height * 3 : 0)}px;
  }
`;

const ContentFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    
  }
`;

const Title = styled.label`
  margin-top: 60px;
  font-family: Montserrat Bold;
  font-weight: bold;
  font-size: 48px;
  color: #222222;
  line-height: 71px;
  text-align: right;
  font-style: normal;
  text-transform: none;

  @media only screen and (max-width: 768px) {
    font-size: 123px;
    line-height: 148px;
    margin-top: 0px;
  }
`;

const Content = styled.div`
  display: inline-block;
`;

const ContentBox = styled.div`
  margin-top: 24px;
  width: 700px;
  height: auto;
  min-height: 573px;
  background: #ffffff;
  border-radius: 24px;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 1818px;
    margin-bottom: 154px;
    padding-bottom: 100px;
    border-radius: 81px;
  }
`;

const MoreButton = styled.button`
  margin-top: 80px;
  margin-bottom: 100px;
  width: 420px;
  background: #fdc448;
  height: 68px;
  font-size: 24px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 16px;
  border: none;
  cursor: pointer;
`;

const ProTop = styled.div`
  width: 700px;
  height: 140px;
  background: linear-gradient(270deg, #ff5061 1%, #ffc75e 100%);
  border-radius: 24px 24px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 48px;
  color: #ffffff;
  line-height: 71px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    width: 1818px;
    height: 358px;
    border-radius: 81px 81px 0px 0px;
    font-size: 123px;
    line-height: 148px;
  }
`;

const NorTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 140px;
  background: #fdc448;
  border-radius: 24px 24px 0px 0px;

  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 48px;
  color: #ffffff;
  line-height: 71px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    width: 1818px;
    height: 358px;
    border-radius: 81px 81px 0px 0px;
    font-size: 123px;
    line-height: 148px;
  }
`;

const TopLeft = styled.div`
  position: absolute;
  width: 104px;
  height: 54px;
  background: #ff4343;
  border-radius: 100px 100px 100px 0px;
  border: 2px solid #ffffff;

  font-family: SourceHanSansCN;
  font-weight: 400;
  font-size: 28px;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    width: 266px;
    height: 143px;
    border: 10px solid #ffffff;
    font-size: 72px;
    line-height: 102px;
  }
`;

const ContentLabel = styled.label`
  height: auto;
  font-family: Montserrat Bold;
  font-weight: bold;
  font-size: 32px;
  color: #222222;
  line-height: 47px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 82px;
    line-height: 97px;
    padding-left: 102px;
    padding-right: 102px;
  }
`;

const ContentLabelSmall = styled.label`
  height: auto;
  font-family: Montserrat Regular;
  font-weight: 400;
  font-size: 24px;
  color: #666666;
  line-height: 36px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 61px;
    line-height: 77px;
    padding-left: 102px;
    padding-right: 102px;
  }
`;

const UploadButton = styled.button`
  position: fixed;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 819px;
  background: #fdc448;
  height: 246px;
  font-size: 82px;
  box-shadow: 31px 31px 0px 0px #373737;
  border-radius: 41px;
  border: none;
  cursor: pointer;
  color: #333333;
`;