import { Layout, message } from "antd";
import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import icon_upload from "../../../assets/historical/icon_upload@2x.png";
import { t } from "../../../languages";
import { px2vw } from "../../../libs/utils";

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#FFFFFF";
};


const Container = styled.div`
  width: 289px;
  height: 189px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f1f1f1;
  border-width: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  border-radius: 24px;
  color: #bdbdbd;
  outline: none;
  transition: border 0.1s ease-in-out;

   cursor: pointer;
`;

interface DropCallback {
  onFile(file:File): void;
}

export default function StyledDropzone(drop: DropCallback) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "application/pdf": [] },
      onDrop(acceptedFiles, fileRejections, event) {
        console.log(acceptedFiles instanceof File);
        if (
          acceptedFiles[0] instanceof File &&
          acceptedFiles[0].name.endsWith(".pdf")
        ) {
          
          drop.onFile(acceptedFiles[0])
        } else {
          message.error(t("请上传正确的PDF文件！"));
        }
      },
    });

  return (
    <div className="container">
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <Layout style={uploadStyle}>
          <div
            style={{
              display: "flex",
              flexDirection:'column',
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: px2vw(56), height: px2vw(56) }}
              alt="icon"
              src={icon_upload}
            />
            <div
              style={{
                marginLeft: 4,
                color: "#333333",
                fontSize: px2vw(24),
                fontWeight: "bold",
              }}
            >
              {t('上传文件')}
            </div>
          </div>
          <div
            style={{
              color: "#333333",
              lineHeight:px2vw(29),
              fontSize: px2vw(20),
              marginTop: px2vw(9),
            }}
          >
            {t('支持格式：PDF')}
          </div>
          <input {...getInputProps()} />
        </Layout>
      </Container>
    </div>
  );
}

const uploadStyle: React.CSSProperties = {
  width: "100%",
  background: "transparent",
  justifyContent: "center",

  display: "flex",
};
