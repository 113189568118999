import ajax, { Format } from "./http";

/** 保存图片的用户 */
export async function SaveInviteImage() {
    ajax.post("api/save_invite_image")
}

/** 保存图片的用户 */
export async function CopyInviteLink() {
    ajax.post("api/copy_invite_link");
}